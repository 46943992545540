<template>
  <div class="sales-category-setting">
    <PageTitle
      title="次卡設定"
      btn="新增"
      @btnClick="$router.push({name: 'CreatePunchCard'})"
    />
    <FiltersContainer>
      <BaseElInput v-model="search.name" clearable placeholder="搜尋次卡名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading.table" :data="tableData" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="圖片" align="center">
          <template slot-scope="scope">
            <img style="margin: auto;" width="90" :src="scope.row.Image? imgSource(scope.row.Image) : ThumbnailDefault">
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="totalAmount" label="可使用次數" align="center" />
        <BaseElTableColumn prop="isExp" label="有效期限" align="center">
          <template slot-scope="scope">
            <!-- TODO: 領取後x天 / 無期限 / yyyy/mm/dd optimize -->
            <span v-if="scope.row.expireSetting.type === 'afterReceive'">領取後{{ scope.row.expireSetting.afterReceiveDays }}天</span>
            <span v-else-if="scope.row.expireSetting.type === 'forever'">無期限</span>
            <span v-else>{{ dateFormat(scope.row.expireSetting.fixedDate, 'YYYY/MM/DD') }}</span>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :hideCopy="false"
              @edit="$router.push({
                name: 'CreatePunchCard',
                params: { punchCardId: scope.row.id }
              })"
              @copy="$router.push({
                name: 'CreatePunchCard',
                params: { punchCardId: scope.row.id },
                query: { copy: true },
              })"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      btnString="刪除"
      @close="deleteDialog = false"
      @delete="deletePunchCard(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted, ref, reactive } from 'vue'
// import Tag from '@/components/Tag/Tag.vue'
// import dayjs from '@/lib/dayjs'
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
// import { mapGetters } from 'vuex'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
// import { defaultRangeMax } from '@/validation'
import ThumbnailDefault from '@/assets/classTicket/thumbnail_default.svg'
// import { checkUserFeature } from '@/store/modules/permission'
import { get } from 'lodash'
import { useTable } from '@/use/table'
import { useRoute } from 'vue-router/composables'
import {
  GetPunchCard,
  GetPunchCardCount,
  DeletePunchCard,
} from '@/api/punchCard'
// Utils
// import { pageStartIndex } from '@/utils/table'
import { imgSrc } from '@/utils/helper'
export default defineComponent({
  name: 'PunchCardSetting',
  components: {
    DeleteDialog,
    EmptyBlock,
    // Tag,
    TableEditBtnGroup,
  },
  setup() {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      shopId,
      dateFormat,
    } = useTable()
    const route = useRoute()
    const search = reactive({
      name: ''
    })
    const deleteDialog = ref(false)
    const selectRow = ref(null)
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: (search.name === '') ? undefined : search.name,
      }
      await Promise.allSettled([
        fetchData(GetPunchCard, payload),
        fetchDataCount(GetPunchCardCount, payload),
      ])
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const deletePunchCard = async () => {
      try {
        await DeletePunchCard({
          shopId: shopId.value,
          id: get(selectRow, 'value.id'),
        })
        await refresh()
        window.$message.success('刪除成功!')
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }
    const imgSource = (image) => {
      return imgSrc(90, image || null)
    }
    onMounted(() => {
      refresh()
    })
    onActivated( async() => {
      if (get(route.query, 'name')) {
        search.name = get(route.query, 'name')
      }
      await refresh()
    })
    return {
      tableData,
      tableDataCount,
      tableOptions,
      loading,
      search,
      deletePunchCard,
      selectRow,
      imgSource,
      dateFormat,
      deleteDialog,
      ThumbnailDefault,
      refresh,
    }
  },
  // data: () => ({
    // ThumbnailDefault,
    // defaultRangeMax,
    // loading: false,
    // nameSearch: '',
    // Dialog
    // deleteDialog: false,
    // selectRow: null,

    // classTicketCount: 0,
    // tableOptions: {
    //   page: 1,
    //   pageLimit: 10,
    // },
    // punchCardList: [],
    // imgSize: 90,
  // }),
  // computed: {
    // ...mapGetters([
    //   'shop',
    //   // 'userFeatures',
    //   // 'userPlanFeature',
    // ]),
    // pageStartIndex () {
    //   return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    // },
    //  開放核銷
    // useTicketUsagePermission () {
    //   return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicketConfig.clientUsage')
    // },
    //  開放轉贈
    // useTicketGivePermission () {
    //   return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicketConfig.clientGive')
    // },
  // },
  // async mounted () {
  //   await this.refresh()
  // },
  // async activated () {
  //   if (get(this.$route.query, 'name')) {
  //     this.nameSearch = get(this.$route.query, 'name')
  //   }
  //   await this.refresh()
  // },
  // methods: {
    // tagType (val) {
    //   let type = 'info'
    //   if (val) type = 'action'
    //   return type
    // },

    // async refresh () {
    //   this.loading = true
    //   await this.getPunchCard()
    //   await this.getPunchCardCount()
    //   this.loading = false
    // },

    //= > 取得堂票
    // async getPunchCard () {
    //   const startIndex = this.pageStartIndex
    //   const limit = this.tableOptions.pageLimit
    //   const [res, err] = await GetPunchCard({
    //     shopId: this.shop,
    //     start: startIndex,
    //     limit,
    //     name: (this.nameSearch === '') ? undefined : this.nameSearch,
    //   })
    //   if (err) {
    //     console.log(err)
    //     this.$message.error(err)
    //     return
    //   }
    //   this.punchCardList = res
    // },

    //= > 取得堂票總數
    // async getPunchCardCount () {
    //   const [res, err] = await GetPunchCardCount({
    //     shopId: this.shop,
    //     name: (this.nameSearch === '') ? undefined : this.nameSearch,
    //   })
    //   if (err) {
    //     console.log(err)
    //     this.$message.error(err)
    //     return
    //   }
    //   this.classTicketCount = res.count
    // },

    // async deletePunchCard () {
    //   try {
    //     await DeletePunchCard({
    //       shopId: this.shop,
    //       id: this.selectRow.id,
    //     })
    //     await this.refresh()
    //     this.$message.success('刪除成功!')
    //   } catch (error) {
    //     console.log(error)
    //     this.$message.error(error)
    //   }
    // },

    // imgSource (image) {
    //   return imgSrc(this.imgSize, image || null)
    // },
    // dateFormat (date) {
    //   if (!date) return '-'
    //   return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    // },
  // },
})
</script>

<style scoped lang="scss">
.break-word {
  word-break: break-word
}
</style>
